<template>
  <div v-if="deliveryInfo">
    <v-text-field
      :label="$t('ec.data.delivery_info.name')"
      dense
      outlined
      class="mb-2"
      v-model="deliveryInfo.name"
    ></v-text-field>

    <v-text-field
      :label="$t('ec.data.delivery_info.phone')"
      dense
      outlined
      class="mb-2"
      v-model="deliveryInfo.phone"
    ></v-text-field>

    <v-text-field
      :label="$t('data.email')"
      dense
      outlined
      class="mb-2"
      v-model="deliveryInfo.email"
    ></v-text-field>

    <!-- 宅配 -->
    <div v-if="$isHomeDeliveryType(deliveryType)">
      <v-text-field
        :label="$t('ec.data.zip')"
        dense
        outlined
        class="mb-2"
        v-model="deliveryInfo.zip"
      ></v-text-field>

      <v-select
        dense
        outlined
        :label="$t('ec.data.city')"
        :items="cities"
        item-text="text"
        item-value="value"
        class="mb-2"
        v-model="deliveryInfo.city"
      ></v-select>

      <v-select
        dense
        outlined
        :label="$t('ec.data.district')"
        :items="districts"
        item-text="text"
        item-value="value"
        class="mb-2"
        v-model="deliveryInfo.district"
      ></v-select>

      <v-text-field
        :label="$t('ec.data.address')"
        dense
        outlined
        class="mb-2"
        v-model="deliveryInfo.address"
      ></v-text-field>
    </div>

    <!-- 超商取貨 -->
    <div v-if="$isCvsDeliveryType(deliveryType)">
      <v-text-field
        :label="$t('ec.data.store_no')"
        dense
        outlined
        class="mb-2"
        v-model="deliveryInfo.store_no"
      ></v-text-field>

      <v-text-field
        :label="$t('ec.data.store_name')"
        dense
        outlined
        class="mb-2"
        v-model="deliveryInfo.store_name"
      ></v-text-field>

      <v-text-field
        :label="$t('ec.data.store_address')"
        dense
        outlined
        class="mb-2"
        v-model="deliveryInfo.store_address"
      ></v-text-field>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import citiesList from 'config/citiesList.json'
export default {
  props: {
    popupConfig: Object,
  },
  data: () => ({
    deliveryInfo: null,
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      this.deliveryInfo = window.eagleLodash.cloneDeep(this.popupConfig.deliveryInfo)
    },
  },
  computed: {
    deliveryType() {
      return this.popupConfig.deliveryType
    },
    cities() {
      return citiesList.map(item => item.city)
    },
    districts() {
      if(!this.deliveryInfo) return null
      if(!this.cities) return []
      const target = citiesList.find(item => item.city === this.deliveryInfo.city)
      if(!target) return []
      const list = target.districts
      return list.map(city => city.name)
    },
    computedDeliveryInfo() {
      let deliveryInfo = window.eagleLodash.cloneDeep(this.deliveryInfo)
      deliveryInfo.full_address = `${deliveryInfo.city}${deliveryInfo.district}${deliveryInfo.address}`
      return deliveryInfo
    },
  },
  watch: {
    deliveryInfo: {
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.computedDeliveryInfo))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
